<template>
  <div class="conversionReport">
    <!-- <div class="main-Title bgff"><h2>Conversion Report</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb5 list-filter">
        <el-form v-model="conversionReport" label-width="80px" size="mini">
          <el-row>
            <el-col :xs="24" :sm="12" :md="6" :lg="8" :xl="6">
              <el-form-item label="OfferId(s)" label-width="70px" class="mb5">
                <el-input
                  v-model="conversionReport.offerIds"
                  placeholder="eg: 22062 or 22062,15673"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="8" :xl="6">
              <el-form-item label="Source(s)" class="mb5">
                <group-select
                  v-model="conversionReport.sourceIds"
                  :data-source="sourcesList"
                  multiple
                  reserve-keyword
                  :loading="sourceLoading"
                  clearable
                  filterable
                  collapse-tags
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="8" :xl="6">
              <el-form-item label="Affiliate(s)" label-width="90px" class="mb5">
                <group-select
                  v-model="conversionReport.affiliateIds"
                  :data-source="affiliatesGroupList"
                  multiple
                  reserve-keyword
                  :loading="affiliateLoading"
                  clearable
                  filterable
                  collapse-tags
                  size="mini"
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="8" :xl="6">
              <el-form-item label="Status" label-width="70px" class="mb5">
                <el-select v-model="conversionReport.status" class="w100">
                  <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="8" :xl="6">
              <el-form-item label="TimeZone" label-width="70px" class="mb5">
                <el-select v-model="conversionReport.timezone" class="w100">
                  <el-option
                    v-for="item in timezoneOption"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="8" :xl="6">
              <el-form-item label="AffSub" label-width="70px" class="mb5">
                <el-input
                  v-model="conversionReport.affSub"
                  placeholder="write affSub here..."
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="8" :xl="6">
              <el-form-item label="Date" label-width="60px" class="mb5">
                <el-date-picker
                  v-model="startToEndDate"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  class="w100"
                  align="right"
                  unlink-panels
                  range-separator="-"
                  start-placeholder="Start Date"
                  end-placeholder="End Date"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="16" :xl="6" align="right">
              <el-form-item label-width="0px" class="mb5">
                <el-button type="primary" :loading="listLoading" @click="searchListClick(1)"
                  >In Browser</el-button
                >
                <el-button type="primary" @click="exportExcelFileClick">Download Excel</el-button>
                <el-button type="primary" @click="approveClick">Approve</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- approve提示框 -->
        <el-dialog title="Tip" :visible.sync="approveVisible" width="300px" append-to-body>
          <div class="del-dialog-cnt">{{ this.approveMessage }}</div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="approveVisible = false">cancel</el-button>
            <el-button type="primary" @click="confirmClick">confirm</el-button>
          </span>
        </el-dialog>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="listLoading"
          style="width:100%;"
          size="mini"
          stripe
          border
          :data="conversionReportList"
          @selection-change="handleConversionSelectionChange"
          @select-all="handleConversionAll"
          highlight-current-row
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
            :resizable="false"
          ></el-table-column>
          <el-table-column
            label="No."
            prop="id"
            min-width="60"
            align="center"
            :resizable="false"
          ></el-table-column>
          <el-table-column
            label="Conversion Time"
            prop="conversionTime"
            min-width="170"
            align="center"
            :resizable="false"
          ></el-table-column>
          <el-table-column
            label="Click Time"
            prop="clickTime"
            align="center"
            min-width="170"
            :resizable="false"
          ></el-table-column>
          <el-table-column
            label="Source"
            prop="sourceId"
            min-width="90"
            align="center"
            :resizable="false"
          ></el-table-column>
          <el-table-column
            label="Affiliate"
            prop="affiliateId"
            align="center"
            min-width="90"
            :resizable="false"
          ></el-table-column>
          <el-table-column
            label="OfferId"
            prop="offerId"
            align="center"
            min-width="100"
            :resizable="false"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="OfferName"
            prop="offerName"
            align="center"
            min-width="100"
            :resizable="false"
          ></el-table-column>
          <el-table-column
            label="Location"
            prop="trackCountry"
            align="center"
            min-width="90"
            :resizable="false"
          ></el-table-column>
          <el-table-column
            label="Aff_sub ID"
            prop="affiliateSub"
            align="center"
            min-width="150"
            :resizable="false"
          ></el-table-column>
          <el-table-column
            label="Click ID"
            prop="transactionId"
            align="center"
            min-width="200"
            :resizable="false"
            show-overflow-tooltip
          ></el-table-column>
          <!-- <el-table-column
		      label="Click IP"
		      prop="trackIp"
		      align="center"
		      min-width="120"
		      :resizable="false"
		    ></el-table-column> -->
          <!-- <el-table-column
		      label="Conversion IP"
		      prop="ip"
		      align="center"
		      min-width="120"
		      :resizable="false"
		    ></el-table-column> -->
          <el-table-column
            label="Status"
            prop="adjusted"
            align="center"
            min-width="100"
            :resizable="false"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.adjusted === 0">approved</span>
              <span v-if="scope.row.adjusted === 1">pending</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Revenue"
            prop="payoutValue"
            align="center"
            min-width="80"
            :resizable="false"
          ></el-table-column>
          <el-table-column
            label="Payout"
            prop="affPayoutValue"
            align="center"
            min-width="70"
            :resizable="false"
          ></el-table-column>
          <el-table-column
            label="Profit"
            prop="profit"
            align="center"
            min-width="70"
            :resizable="false"
          ></el-table-column>
        </el-table>
        <pagination
          class="block pagePanel"
          :pageTotal="conversionTotal"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pageSize"
          :currentPage="currentPage"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>
<script>
  import ConversionReportCon from '../controllers/report/ConversionReport';

  export default {
    name: 'ConversionReport',
    ...ConversionReportCon,
  };
</script>
<style lang="scss" scoped></style>
<style lang="scss"></style>
