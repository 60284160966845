import { api, getConversionReport, sendAffPb } from 'api/report';

import Pagination from '@/components/pagination';
import GroupSelect from '@/components/GroupSelect';

import dateUtils from '@/utils/dateutils.js';
import qs from 'querystring';
import { myMixin } from '@/mixins/mixins.js';
import optionData from '@/assets/js/optionData';
import { mapState, mapActions } from 'vuex';
import { filterObject } from 'utils/utils';

export default {
  mixins: [myMixin],
  components: {
    Pagination,
    GroupSelect,
  },
  data() {
    return {
      affiliateIdArray: [],
      sourceIdArray: [],
      listLoading: false,
      approveVisible: false,
      pageSize: 20,
      currentPage: 1,
      conversionTotal: null,
      idArr: [],
      approveMessage: '',
      approveSelectionList: [],
      conversionReportList: [],
      startToEndDate: '',
      timezoneOption: optionData.timezoneOption,
      statusOptions: [
        {
          label: 'ALL',
          value: null,
        },
        {
          label: 'APPROVED',
          value: 0,
        },
        {
          label: 'PENDING',
          value: 1,
        },
      ],
      conversionReport: {
        sourceIds: null,
        affiliateIds: null,
        status: null,
        offerIds: null,
        fromDate: null,
        toDate: null,
        affSub: null,
        timezone: '+00:00',
      },
    };
  },
  computed: {
    ...mapState('affiliate', {
      affiliatesGroupList: (state) => state.affiliatesGroupList,
      affiliateLoading: (state) => state.affiliateLoading,
    }),
    ...mapState('source', {
      sourcesList: (state) => state.sourcesList,
      sourceLoading: (state) => state.sourceLoading,
    }),
  },
  mounted() {
    //默认选择
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() + 3600 * 1000 * 24);
    this.startToEndDate = [];
    this.startToEndDate[0] = dateUtils.date2StrYMD(end);
    this.startToEndDate[1] = dateUtils.date2StrYMD(start);
    this.getAffiliateList();
    this.getSourceIdsList();
  },
  methods: {
    ...mapActions('affiliate', ['getAffiliateList']),
    ...mapActions('source', ['getSourceIdsList']),
    approveClick() {
      this.approveMessage = '';
      if (this.approveSelectionList != null && this.approveSelectionList.length > 0) {
        this.approveVisible = true;
        this.approveMessage =
          'You choose to change ' +
          this.approveSelectionList.length +
          '  conversions to "Approve"?';
      } else {
        this.$message.warning('please select approve data!');
      }
    },
    searchListClick(curPage) {
      this.listLoading = true;
      if (curPage) {
        this.curPage = curPage;
      }
      let param = {
        ...this.conversionReport,
        page: this.currentPage,
        pageSize: this.pageSize,
      };
      param['fromDate'] = this.startToEndDate[0];
      param['toDate'] = this.startToEndDate[1];
      if (Array.isArray(param.sourceIds) && param.sourceIds.length > 0) {
        param.sourceIds = param.sourceIds.join(',');
      } else {
        delete param.sourceIds;
      }
      if (Array.isArray(param.affiliateIds) && param.affiliateIds.length > 0) {
        param.affiliateIds = param.affiliateIds.join(',');
      } else {
        //根据PM有的渠道权限去查询
        let idArr = new Array();
        if (this.affiliateList != null && this.affiliateList.length > 0) {
          this.affiliateList.forEach((item) => idArr.push(item.id));
        }
        param.affiliateIds = idArr.length > 0 ? idArr : null;
      }
      param = filterObject(param);
      getConversionReport(param).then((response) => {
        this.listLoading = false;
        if (response.code === 200) {
          this.conversionReportList = response.result;
          this.conversionTotal = response.total;
        } else {
          this.$message.error(response.message);
        }
      });
    },
    updateClick(row) {
      let param = {
        offerId: row.offerId,
        status: row.status,
      };
      console.log(param);
      //等后端给更新status接口
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.searchListClick();
    },
    exportExcelFileClick() {
      let param = {
        offerIds: null,
        sourceIds: null,
        affiliateIds: null,
        status: null,
      };
      param['fromDate'] = this.startToEndDate[0];
      param['toDate'] = this.startToEndDate[1];
      param['timezone'] = this.conversionReport.timezone;
      param['status'] = this.conversionReport.status;
      if (this.conversionReport.offerIds != null && this.conversionReport.offerIds.length > 0) {
        if (this.conversionReport.offerIds.indexOf('，') != -1) {
          param.offerIds = this.conversionReport.offerIds.replace(/，/gi, ',');
        } else {
          param.offerIds = this.conversionReport.offerIds;
        }
      }

      if (this.conversionReport.sourceIds != null && this.conversionReport.sourceIds.length > 0) {
        param.sourceIds = this.conversionReport.sourceIds.join(',');
      }

      if (
        this.conversionReport.affiliateIds != null &&
        this.conversionReport.affiliateIds.length > 0
      ) {
        param.affiliateIds = this.conversionReport.affiliateIds.join(',');
      }
      param = filterObject(param);
      const strParam = qs.stringify(param);
      window.open(`${api.DOWNLOAD_CONVERSION_REPORT}?${strParam}`, '_blank');
    },

    handleConversionSelectionChange(val) {
      this.approveSelectionList = [];
      this.approveSelectionList = val;
    },
    handleConversionAll(val) {
      this.approveSelectionList = [];
      this.approveSelectionList = val;
    },
    confirmClick() {
      const length = this.approveSelectionList.length;
      this.idArr = [];
      for (let i = 0; i < length; i++) {
        this.idArr.push(this.approveSelectionList[i].id);
      }
      const ids = this.idArr;
      sendAffPb(ids).then((response) => {
        if (response.code === 200) {
          this.$message({
            message: 'Approve Success',
            type: 'success',
          });
          this.searchListClick();
        } else {
          this.$message.error('Approve Error:' + response.message);
        }
      });
      this.approveVisible = false;
    },
  },
};
